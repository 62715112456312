<template>
  <div class="canvas-container">
    <div v-if="false">
      <ontology-view :update-object="updateObject" />
    </div>
    <b-overlay
      :show="isGraphLoadingStatus"
      variant="transparent"
      :opacity="1"
      rounded="sm"
      class="canvas"
    >
      <ontology-view-joint v-if="!isGraphLoadingStatus" :update-object="updateObject" @sidebar="onSidebar" />
    </b-overlay>

    <b-sidebar
      id="ontologyDetailsSidebar"
      ref="sidebar"
      class="resizable"
      :width="`${contextMenuWidth}px`"
      bg-variant="white"
      right
      no-close-on-route-change
      :visible.sync="isSidebarVisible"
      @hidden="isSidebarVisible = false"
    >
      <div class="resize-handle" @mousedown.prevent="initResize" />
      <EntityDetailsFn v-if="isFunction" @clicked="doUpdate" />
      <EntityDetailsSys v-if="isPerformer && !isFunction" @clicked="doUpdate" />
      <EntityDetails v-if="!isPerformer && !isFunction" @clicked="doUpdate" />
    </b-sidebar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OntologyView from '@/components/Domain/OntologyView.vue'
import OntologyViewJoint from '@/components/Domain/OntologyViewJoint.vue'
import EntityDetails from '@/components/Domain/EntityDetails.vue'
import EntityDetailsFn from '@/components/Domain/EntityDetailsFn.vue'
import EntityDetailsSys from '@/components/Domain/EntityDetailsSys.vue'

export default {
  name: 'Ontology',
  components: {
    EntityDetailsSys,
    EntityDetailsFn,
    OntologyView,
    OntologyViewJoint,
    EntityDetails,
  },
  data() {
    return {
      updateObject: null,
      isSidebarVisible: false,
      isGraphLoadingStatus: false,
      mini: false,
      contextMenuWidth: (localStorage.getItem('ontologySidebarWidth') || 450),
    }
  },
  computed: {
    ...mapState('domainModel', ['composition_tree', 'selected_entity2']),
    currentRouteName() {
      return this.$route.name
    },
    isPerformer() {
      return this.selected_entity2 && this.isTreatedLikeStereotype(this.selected_entity2.context.labels, 'Performer') && this.isSidebarVisible
    },
    isFunction() {
      return this.selected_entity2 && this.isTreatedLikeStereotype(this.selected_entity2.context.labels, 'Function') && this.isSidebarVisible
    },
  },
  methods: {
    isTreatedLikeStereotype(labels, treatedLike) {
      // does any specific component label get treated_like component stereotype?
      const { stereotypes } = this.$store.state.constants
      // eslint-disable-next-line
      for (const specific of labels) {
        if (specific === treatedLike) return true
        const stereotypeConstant = stereotypes.find(s => s.name === specific)
        if (stereotypeConstant && stereotypeConstant?.treat_like?.find(t => t === treatedLike)) {
          return true
        }
      }
      return false
    },
    onSidebar(isVisible) {
      this.isSidebarVisible = isVisible
    },
    initResize(event) {
      window.addEventListener('mousemove', this.startResizing)
      window.addEventListener('mouseup', this.stopResizing)
    },
    startResizing(event) {
      const { sidebar } = this.$refs
      const currentSidebarWidth = parseInt(sidebar.width, 10)
      const newWidth = currentSidebarWidth - (event.clientX - document.getElementById('ontologyDetailsSidebar').getBoundingClientRect().left)
      this.contextMenuWidth = Math.max(newWidth, 450)
      localStorage.setItem('ontologySidebarWidth', `${this.contextMenuWidth}`)
    },
    stopResizing(event) {
      window.removeEventListener('mousemove', this.startResizing)
      window.removeEventListener('mouseup', this.stopResizing)
    },
    doUpdate() {
      this.$bvModal.hide('replace-names-modal')
      console.log('Updated Object: ', this.selected_entity2)
      this.updateObject = this.selected_entity2
    },
  },
}
</script>

<style scoped lang="scss">
body {
  height: 60vh;
  box-sizing: border-box;
  margin: 0;

  .content-wrapper {
    .content-body {
      width: 100%;
      height: 100%;
    }
  }

  .canvas {
    width: 100%;
    height: 100%;

    .joint-paper {
      border: 1px solid #000000;
    }
  }

  .toolbar-text {
    display: none;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/plugins/extensions/ext-component-context-menu.scss';

.canvas-container {
  width: revert;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  top: 7rem;
}

.entity-pane {
  border-left: 1px dotted;
  overflow-y: auto;
}

.resizable {
  position: relative; /* Needed to position the resize handle */
  overflow: hidden;
}

.resize-handle {
  z-index: 99999;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px; /* Width of the handle */
  cursor: ew-resize; /* Horizontal resize cursor */
}

.resize-handle:hover {
  border-left: 2px solid rgb(133, 184, 255);
}
</style>
